var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.generos,"label":"Sexo (*)","rules":_vm.rules.required,"item-text":"value","item-value":"value","outlined":"","dense":""},model:{value:(_vm.generoSelected),callback:function ($$v) {_vm.generoSelected=$$v},expression:"generoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('currency-input',{attrs:{"label":"Importe (*)","oulined":"","dense":"false","outlined":"false","options":_vm.currencyOptions,"rules":_vm.importe > 0 ? [] : _vm.rules.required},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Edad desde (*)","dense":"","outlined":"","rules":_vm.edadDesde === 0
                  ? []
                  : _vm.rules.required.concat([
                      _vm.rules.validDateRange(
                        parseInt(_vm.edadDesde),
                        parseInt(_vm.edadHasta)
                      )
                    ])},model:{value:(_vm.edadDesde),callback:function ($$v) {_vm.edadDesde=$$v},expression:"edadDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Edad hasta (*)","dense":"","outlined":"","rules":_vm.edadHasta === 0
                  ? []
                  : _vm.rules.required.concat([
                      _vm.rules.validDateRange(
                        parseInt(_vm.edadDesde),
                        parseInt(_vm.edadHasta)
                      )
                    ])},model:{value:(_vm.edadHasta),callback:function ($$v) {_vm.edadHasta=$$v},expression:"edadHasta"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde (*)","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","outlined":"","dense":"","rules":_vm.rules.required.concat(
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))
                    ) || 'Formato incorrecto'},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta (*)","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","outlined":"","autocomplete":"not","dense":"","rules":_vm.rules.required.concat(
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))
                    ) || 'Formato incorrecto'},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período desde (*)","hint":"Formato AAAAMM","persistent-hint":"","type":"text","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [
                    _vm.rules.validDateRange(
                      _vm.periodoAportesDesde,
                      _vm.periodoAportesHasta
                    )
                  ],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoAportesDesde),callback:function ($$v) {_vm.periodoAportesDesde=$$v},expression:"periodoAportesDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período hasta (*)","type":"text","hint":"Formato AAAAMM","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","persistent-hint":"","rules":_vm.rules.required.concat(
                  [
                    _vm.rules.validDateRange(
                      _vm.periodoAportesDesde,
                      _vm.periodoAportesHasta
                    )
                  ],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoAportesHasta),callback:function ($$v) {_vm.periodoAportesHasta=$$v},expression:"periodoAportesHasta"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }