<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="itemsValoresCapitaAnsesJubilacion"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="montoCapitaId"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="3" class="pt-12 pr-0">
                  <v-switch
                    class="pr-0 py-0"
                    v-model="habilitarFecha"
                    label="Ver registros a la fecha:"
                    dense
                    outlined
                  ></v-switch>
                </v-col>
                <v-col cols="2" class="pt-10 pl-0 pr-5">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    :disabled="!habilitarFecha"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-bind="attrs"
                        @change="loadValoresCapitaAnsesJubilados()"
                        autocomplete="not"
                        v-on="on"
                        v-model.trim="fechaToday"
                        hint="Formato dd/mm/aaaa"
                        :disabled="!habilitarFecha"
                        :append-icon="calendarIcon"
                        type="text"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        v-mask="'##/##/####'"
                        @blur="fecha = parseDateToIso(fechaToday)"
                        :rules="
                          fechaToday == null || fechaToday == ''
                            ? []
                            : rules.validDate
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha"
                      no-title
                      :disabled="!habilitarFecha"
                      @change="
                        fechaToday = formatDate(fecha);
                        loadValoresCapitaAnsesJubilados();
                      "
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="7" align="end" v-if="canCreate" class="pt-10">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            <span>{{ addDecimals(item.importe) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar valor de cápita</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteValorCapita(item.montoCapitaId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar valor de cápita</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditValoresCapitaAnsesJubilados
        :editValCapitaAnsesJubilados="editValCapitaAnsesJubilados"
        @closeAndReload="closeAndReload"
      ></EditValoresCapitaAnsesJubilados>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'20%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditValoresCapitaAnsesJubilados from "@/components/modules/cuotas/aportes/EditValoresCapitaAnsesJubilados.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditValoresCapitaAnsesJubilados,
    GoBackBtn,
    Ayuda
  },
  name: "ValoresCapitaAnsesJubilados",
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    calendarIcon: enums.icons.CALENDAR,
    isFormValid: false,
    search: "",
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    fechaToday: null,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.VALORES_CAPITA_ANSES_JUBILACION,
    habilitarFecha: true,
    fecha: null,
    menu1: false,
    itemsValoresCapitaAnsesJubilacion: [],
    topeRemunId: null,
    routeToGo: "ConfiguracionDDJJAportes",
    title: enums.titles.VALORES_CAP_ANSES_JUBILADOS,
    titleDelete: "¿Eliminar registro?",
    rules: rules,
    headers: [
      {
        text: "Sexo",
        value: "sexo",
        sortable: true
      },
      {
        text: "Importe",
        value: "importe",
        align: "end",
        sortable: false
      },
      {
        text: "Edad desde",
        value: "edadDesde",
        sortable: false,
        align: "right"
      },
      {
        text: "Edad hasta",
        value: "edadHasta",
        sortable: false,
        align: "right"
      },
      {
        text: "Fecha desde",
        value: "fechaDesde",
        sortable: false
      },
      {
        text: "Fecha hasta",
        value: "fechaHasta",
        sortable: false
      },
      {
        text: "Período aportes desde",
        value: "periodoAportesDesde",
        sortable: true
      },
      {
        text: "Período aportes hasta",
        value: "periodoAportesHasta",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    let traerFecha = this.hoyFecha();
    this.fechaToday = traerFecha;
    this.loadValoresCapitaAnsesJubilados();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  watch: {
    fechaToday() {
      if (this.fechaToday === null) this.loadValoresCapitaAnsesJubilados();
    },
    habilitarFecha() {
      if (this.habilitarFecha === false) this.fechaToday = null;
      else {
        this.fechaToday = this.hoyFecha();
        this.loadValoresCapitaAnsesJubilados();
      }
    }
  },
  methods: {
    ...mapActions({
      getValoresCapitaANSES: "configuracion/getValoresCapitaANSES",
      deleteValorCapitaANSES: "configuracion/deleteValorCapitaANSES",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVA_CAPITA_ANSES_JUBILADOS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_CAPITA_ANSES_JUBILADOS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_CAPITA_ANSES_JUBILADOS:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    hoyFecha() {
      let now = new Date();
      let day = ("0" + now.getDate()).slice(-2);
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let today = day + "/" + month + "/" + now.getFullYear();

      return today;
    },

    async loadValoresCapitaAnsesJubilados() {
      if (this.fechaToday === null) this.fecha = null;
      if (this.fechaToday !== null)
        this.fecha = this.parseDateToIso(this.fechaToday);
      this.isLoading = true;
      const data = await this.getValoresCapitaANSES(this.fecha);
      this.itemsValoresCapitaAnsesJubilacion = data;
      this.isLoading = false;
    },
    addDecimals(x) {
      if (x == undefined) return;
      return getFormatPrice(x);
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    deleteValorCapita(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteValorCapitaANSES({
        montoCapitaId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadValoresCapitaAnsesJubilados();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editValCapitaAnsesJubilados = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadValoresCapitaAnsesJubilados();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
